<template>
  <div class="flex flex-col w-full mt-6 px-15 lg:px-4 sm-ws:px-10">
    <transition name="slide">
      <ToolBar
        title="Filtres"
        v-show="filtersToolbarIsOpened"
        @close="toggleOpenFiltersToolBar()"
        @restFilter="resetFilter()"
      >
        <div class="flex flex-col justify-between space-y-4">
          <div>
            <label>Régions</label>
            <searchable-select
              v-model="filters.region"
              option-label="name"
              :options="regions"
              placeholder="Régions"
              class="w-full mt-1"
            />
          </div>
          <div>
            <label>Département</label>
            <searchable-select
              v-model="filters.departement"
              option-label="name"
              :options="departements"
              placeholder="Département"
              class="w-full mt-1"
            />
          </div>
          <div>
            <label>Commune</label>
            <searchable-select
              v-model="filters.commune"
              :options="communes"
              placeholder="Commune"
              class="w-full mt-1"
            />
          </div>
          <div>
            <label>Créateur</label>
            <searchable-select
              v-model="filters.creator"
              placeholder="Créateur"
              option-label="full_name"
              :options="list_admins"
              class="w-full mt-1"
            />
          </div>
          <div>
            <FilterSlider
              ref="surfaceFilterSlider"
              keyItem="surface_terrain"
              :items="surface_terrain"
              :limits="surfaceSlidersLimits"
              type="marketPlaceFilter"
              @sliderChanged="sliderChanged"
            />
            <FilterSlider
              ref="caFilterSlider"
              :items="ca_potentiel"
              :limits="caSlidersLimits"
              keyItem="ca_potentiel"
              type="marketPlaceFilter"
              @sliderChanged="sliderChanged"
            />
          </div>
          <div>
            <div class="flex items-stretch" style="margin: 10px 0 !important">
              <Checkbox
                :val="filters.availabe_when_brouillon"
                id="availabe_when_brouillon"
                v-model="filters.availabe_when_brouillon"
                class="pr-10"
              />
              <label
                for="availabe_when_brouillon"
                class="cursor-pointer text-promy-gray-650 text-sm flex items-center mt-1"
                >Bientôt disponible</label
              >
            </div>
          </div>
          <div>
            <div class="flex items-stretch" style="margin: 10px 0 !important">
              <Checkbox
                :val="filters.vendu"
                id="vendu"
                v-model="filters.vendu"
                class="pr-10"
              />
              <label
                for="vendu"
                class="cursor-pointer text-promy-gray-650 text-sm flex items-center mt-1"
                >Vendu</label
              >
            </div>
          </div>
          <div>
            <div class="flex items-stretch" style="margin: 10px 0 !important">
              <Checkbox
                :val="filters.is_anonymous"
                id="is_anonymous"
                v-model="filters.is_anonymous"
                class="pr-10"
              />
              <label
                for="is_anonymous"
                class="cursor-pointer text-promy-gray-650 text-sm flex items-center mt-1"
                >Anonymes</label
              >
            </div>
          </div>
        </div>
        <hr class="my-4 hidden lg:block" />
        <div class="hidden lg:block">
          <p class="font-extrabold text-promy-gray-650 font-main">
            Trier par :
          </p>
        </div>
      </ToolBar>
    </transition>
    <div
      class="flex font-extrabold leading-2 text-promy-purple-400 text-promy-2xl font-main sm:text-lg xl:mb-12 lg:mb-4"
    >
      <span class="text-promy-gray-650">Dossiers promoteur</span>
    </div>
    <div
      class="flex justify-between items-center md:flex-col md:items-start my-6 lg:text-base xl:text-lg sm:space-y-4"
    >
      <div class="w-fit sm:w-full flex justify-between items-center">
        <span class="font-extrabold text-promy-gray-650 font-main">
          Résultat ({{ total }} terrains)
        </span>
        <button
          v-if="
            $can('consultation,gestion_utilisateur') ||
            $can('edite,gestion_utilisateur')
          "
          @click="toggleOpenFiltersToolBar()"
          class="items-center bg-white justify-center w-10 h-10 text-sm font-extrabold uppercase border-2 rounded-full cursor-pointer text-promy-green-300 border-promy-green-300 font-main hidden sm:flex"
        >
          <i class="text-sm fa fa-filter"></i>
        </button>
      </div>
      <div
        class="md:w-full flex sm:flex-col justify-end md:justify-between items-center space-x-4 sm:space-x-0 sm:space-y-4"
      >
        <div class="sm:w-full">
          <pro-input v-model="filters.search" placeholder="Rechercher" />
        </div>
        <button
          v-if="$can('consultation,dossier_promoteur')"
          @click="downloadCSV"
          class="flex items-center justify-center px-4 py-2 text-sm font-extrabold text-white uppercase border-2 rounded-full cursor-pointer bg-promy-green-300 border-promy-green-300 font-main sm:w-full"
        >
          Exporter CSV ({{ dossier_promoteur_selected.length }})
        </button>
        <router-link
          v-if="
            $can('consultation,dossier_promoteur') ||
            $can('edite,dossier_promoteur')
          "
          to="/dossier-promoteurs/informations/"
          class="flex items-center justify-center px-4 py-2 text-sm font-extrabold text-white uppercase border-2 rounded-full cursor-pointer bg-promy-green-300 border-promy-green-300 font-main sm:w-full"
        >
          <span class="">Ajouter un terrain </span>
          <i class="pl-2 text-sm fa fa-plus"></i>
        </router-link>
        <button
          v-if="
            $can('consultation,gestion_utilisateur') ||
            $can('edite,gestion_utilisateur')
          "
          @click="toggleOpenFiltersToolBar()"
          class="flex items-center bg-white justify-center w-10 h-10 text-sm font-extrabold uppercase border-2 rounded-full cursor-pointer text-promy-green-300 border-promy-green-300 font-main sm:hidden"
        >
          <i class="text-sm fa fa-filter"></i>
        </button>
      </div>
    </div>
    <hr class="bg-promy-gray-300 h-[2px] mb-4" />
    <div class="flex items-center">
      <div
        class="relative cursor-pointer"
        v-for="(tab, i) in tabs"
        :key="i"
        @click="changeCurrentTab(tab.component)"
      >
        <p
          class="uppercase font-main text-base font-bold px-4 py-2"
          :class="[
            current_tab === tab.component
              ? 'text-promy-gray-700'
              : 'text-promy-gray-500',
          ]"
        >
          {{ tab.label }}
        </p>
        <hr
          v-if="current_tab === tab.component"
          class="absolute bottom-0 right-0 bg-promy-green-300 w-full h-[4px]"
        />
      </div>
    </div>
    <hr class="bg-promy-gray-300 h-[2px]" />

    <div>
      <component
        :is="current_tab"
        :filters="filters"
        :sort_list="sort_list"
        :reload_dossier_promoteur="reload_dossier_promoteur"
        :let_save_scroll_position="let_save_scroll_position"
        @setFilter="setFilter"
        @setDataMeta="setDataMeta"
        @displayModal="displayModal"
        @dossierPromoteurReloaded="reload_dossier_promoteur = false"
        @scrollPositionIsSaved="let_save_scroll_position = false"
      />
    </div>

    <ValidateModal
      v-if="showModal"
      @isCancel="showModal = false"
      @isValide="archiveDossierPromoteur()"
    >
      <slot slot="message_body"
        >Êtes-vous sûr de vouloir archiver ce dossier ?</slot
      >
      <slot slot="button_valide">Continuer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
    <ValidateModal
      v-if="showModalTransfer"
      @isCancel="showModalTransfer = false"
      @isValide="transferResponsability()"
    >
      <slot slot="message_body">
        <div class="flex items-center space-x-2 py-4">
          <p>Transférer la responsabilité à</p>
          <searchable-select
            v-model="responsable"
            option-label="full_name"
            :options="list_admins"
            placeholder="Responsable"
            class="w-62 sm:mb-2"
          />
        </div>
      </slot>
      <slot slot="button_valide">Transférer</slot>
      <slot slot="button_cancel">Annuler</slot>
    </ValidateModal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ProInput from '../../../components/UI/Inputs/ProInput.vue'
import TerrainsArchive from './TerrainsArchive'
import TerrainsBrouillon from './TerrainsBrouillon'
import TerrainsEnCours from './TerrainsEnCours'

export default {
  components: {
    ProInput,
    TerrainsArchive,
    TerrainsBrouillon,
    TerrainsEnCours,
  },

  data() {
    return {
      tabs: [
        {
          component: 'TerrainsEnCours',
          label: 'En cours',
        },
        {
          component: 'TerrainsBrouillon',
          label: 'Brouillon',
        },
        {
          component: 'TerrainsArchive',
          label: 'Terminé/Archivé',
        },
      ],
      current_tab: 'TerrainsEnCours',
      sort_list: [
        {
          title: 'Statut',
          sortKey: 'status',
          classes: 'w-[6%]',
        },
        {
          title: 'Avancement',
          sortKey: 'avancement',
          classes: 'w-[8%]',
        },
        {
          title: 'N° dossier',
          sortKey: 'dossier_number',
          classes: 'w-[9%]',
        },
        {
          title: 'Commune',
          sortKey: 'commune',
          classes: 'w-[16%]',
        },
        {
          title: 'CP',
          sortKey: 'code_postal',
          classes: 'w-[6%]',
        },
        {
          title: 'Ca potentiel',
          sortKey: 'ca_potentiel',
          classes: 'w-[8.5%]',
        },
        {
          title: 'Surf.',
          sortKey: 'surface_terrain',
          classes: 'w-[7%]',
        },
        {
          title: 'Type',
          sortKey: 'type_de_projet',
          classes: 'w-[7.5%]',
        },
        {
          title: 'Offres',
          sortKey: 'count_offre',
          classes: 'w-[6.7%]',
        },
        {
          title: 'Vues',
          sortKey: 'seens',
          classes: 'w-[5.5%]',
        },
        {
          title: 'Temps restant',
          sortKey: 'end_at',
          classes: 'w-[13%]',
        },
        // {
        //   title: 'Resp',
        //   sortKey: 'responsable',
        //   classes: 'w-[11.5%]',
        // },
      ],
      filtersToolbarIsOpened: false,
      Status: [
        'Brouillon',
        'Commercialisation en attente',
        'Commercialisation en cours',
        'Commercialisation terminée',
        'Suivi de la vente',
        'Dossier clos',
      ],
      surface_terrain: {
        key: 'surfaceDeTerrain',
        name: 'Surface terrain',
        sign: 'm²',
        type: 'slider',
        value: [],
      },
      ca_potentiel: {
        key: 'ca_potentiel',
        name: 'Ca potentiel',
        sign: '€',
        type: 'slider',
        value: [],
      },
      caSlidersLimits: [0, 0],
      surfaceSlidersLimits: [0, 0],
      departements: [],
      regions: [],
      communes: [],
      open: null,
      showModal: false,
      showModalTransfer: false,
      dossierTobeArchived: null,
      archivedStatus: [],
      id_to_be_transfer: null,
      total: null,
      reload_dossier_promoteur: false,
      let_save_scroll_position: false,
    }
  },
  created() {
    this.$store.commit('dossierpromoteur/RESET_DOSSIER_PROMOTEUR_SELECTED')
    if (this.dossiers_promoteur_tab)
      this.current_tab = this.dossiers_promoteur_tab
  },
  watch: {
    'filters.vendu': {
      handler(value) {
        if (!value) return
        this.changeCurrentTab('TerrainsArchive')
      },
    },
    'filters.availabe_when_brouillon': {
      handler(value) {
        if (!value) return
        this.changeCurrentTab('TerrainsBrouillon')
      },
    },
    showModalTransfer(val) {
      if (!val) {
        this.id_to_be_transfer = null
        this.responsable = null
      }
    },
  },
  methods: {
    changeCurrentTab(component) {
      this.current_tab = component
      this.$store.commit(
        'userNavigationState/SET_DOSSIERS_PROMOTEUR_SCROLL_POSITION',
        null,
      )
    },
    setFilter(filter) {
      this.filters = filter
    },

    setDataMeta({ meta }) {
      if (meta.filters) {
        this.departements = meta.filters.departements
        this.regions = meta.filters.regions
        this.communes = meta.filters.communes
        this.caSlidersLimits = [
          meta.filters.min_ca_potentiel,
          meta.filters.max_ca_potentiel,
        ]
        this.surfaceSlidersLimits = [
          meta.filters.min_surface_terrain,
          meta.filters.max_surface_terrain,
        ]
      }
      this.total = meta.total
    },

    toggleOpenFiltersToolBar() {
      this.filtersToolbarIsOpened = !this.filtersToolbarIsOpened
    },

    downloadPDF(item) {
      this.$http
        .get(`admin/marketplace/dossier-promoteur/${item.id}/pdf`, {
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${item.commune.replace(/\s/g, '-')}.pdf`
          link.click()
        })
        .catch((err) => {
          var enc = new TextDecoder('utf-8')
          var arr = new Uint8Array(err.response.data)
          let error = JSON.parse(enc.decode(arr)).error
          if ((err.response.status = 429)) {
            this.$toaster.warning(error)
          }
        })
    },
    async archiveDossierPromoteur() {
      this.reload_dossier_promoteur = false
      let response = await this.$http.post(
        `admin/dossier-promoteurs/archive/${this.dossierTobeArchived}`,
      )
      if (response.status === 200) {
        this.reload_dossier_promoteur = true
        this.showModal = false
        this.dossierTobeArchived = null
        this.$toaster.warning('Le dossier a été a avec succès')
      }
    },

    resetFilter() {
      this.sorting_by = ''
      this.filters = {
        search: '',
        departement: '',
        region: '',
        commune: '',
        surface_terrain: [],
        ca_potentiel: [],
        availabe_when_brouillon: '',
        vendu: '',
      }
      this.$refs.surfaceFilterSlider.resetFilter()
      this.$refs.caFilterSlider.resetFilter()
    },
    displayModal(id) {
      this.showModal = true
      this.dossierTobeArchived = id
    },

    // displayModalTransfer(id) {
    //   this.showModalTransfer = true
    //   this.id_to_be_transfer = id
    // },

    async transferResponsability() {
      let response = await this.$store.dispatch(
        'dossierpromoteur/transferResponsibility',
        { id: this.id_to_be_transfer, responsable: this.responsable },
      )

      if (response.status === 201) {
        this.$toaster.success(
          'La responsabilité du terrain a bien été transférée',
        )
        this.reload_dossier_promoteur = true
      }
      this.showModalTransfer = false
    },

    downloadCSV() {
      if (_.isEmpty(this.dossier_promoteur_selected)) return
      let checkedIDS = this.dossier_promoteur_selected.map((el) => el.id)
      this.$http
        .get(`admin/dossier-promoteurs/export`, {
          params: {
            terrain_ids: checkedIDS,
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          let blob = new Blob([response.data])
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob, {
            type: 'text/csv',
          })
          link.download = 'dossiers_promoteur.csv'
          link.click()
        })
        .catch((err) => {
          this.$toaster.warning("une erreur s'est produite")
        })
    },

    sliderChanged(slider, keyItem) {
      if (keyItem === 'ca_potentiel') this.filters.ca_potentiel = slider
      else this.filters.surface_terrain = slider
    },
  },

  computed: {
    ...mapGetters({
      list_admins: 'dossierpromoteur/getListAdmins',
      dossiers_promoteur_tab: 'userNavigationState/getDossiersPromoteurTab',
      dossier_promoteur_selected:
        'dossierpromoteur/getDossierPromoteurSelected',
    }),
    responsable: {
      get() {
        return this.$store.getters['dossierpromoteur/getSelectedResponsable']
      },
      set(value) {
        this.$store.commit('dossierpromoteur/SET_SELECTED_RESPONSABLE', value)
      },
    },
    filters: {
      get() {
        return this.$store.getters[
          'userNavigationState/getDossiersPromoteurFilter'
        ]
      },
      set(value) {
        this.$store.commit(
          'userNavigationState/SET_DOSSIERS_PROMOTEUR_FILTER',
          value,
        )
      },
    },
  },

  beforeRouteLeave(to, from, next) {
    this.let_save_scroll_position = true
    this.$store.commit(
      'userNavigationState/SET_DOSSIERS_PROMOTEUR_TAB',
      this.current_tab,
    )
    next()
  },
}
</script>
<style lang="scss" scoped>
.liste-dossier::-webkit-scrollbar {
  width: 0.3em;
}

.liste-dossier::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #949494;
}

.liste-dossier::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #f2f2f2;
}
</style>
